.input {
    margin-bottom: 16px;
}

.action-buttons-custom {
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    width: 100%;

    margin-top: 40%;
}