.space {
    margin-bottom: 20px;
    margin-top: 20px;
}

.action-buttons-custom-event {
    display: flex;

    flex-direction: row;

    justify-content: space-between;

    width: 100%;

    margin-top: 10%;
}