.content {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 10vh;
    margin-right: 20px;
    width: 100%;
  }
  
  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .itemForm {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    width: 100%;
  }